<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='hal m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.languages.66ccb00b') }}</div>
        </div>
        <card-element class='py-8 mt-24'>
          <div class='flex justify-between py-16 cursor-pointer'
            @click='changeLocale("en")'>
            <div>English</div>
            <template v-if='locale === "en"'>
              <i class='icon text-gray-500'>check</i>
            </template>
          </div>
          <div class='flex justify-between py-16 border-gray-300 border-t cursor-pointer'
            @click='changeLocale("th")'>
            <div>ภาษาไทย</div>
            <template v-if='locale === "th"'>
              <i class='icon text-gray-500'>check</i>
            </template>
          </div>
        </card-element>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation
    },

    computed: {
      locale () {
        return this.$i18n.locale
      }
    },

    methods: {
      changeLocale (locale) {
        localStorage.setItem('locale', locale)
        location.assign(this.$router.resolve({ name: 'home' }).href)
      }
    }
  }
</script>

<style lang='scss' scoped>
  .hal {
    max-width: 360px;
  }
</style>
